import * as React from "react";
import * as z from "zod";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  userEmailLoginSchema,
  userEmailRegisterSchema,
  passwordLoginSchema,
} from "server/validations/auth";
import { Description } from "components/ui/Typography";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import { OuthButtons } from "./auth-common";

import { getBrowserSupabase } from "utils/supabase-as-lazy";
import { useLoginOrSignUpWithEmail } from "./utils";
import { Box } from "components/ui/box";
import { ResponsiveText } from "modules/marketing-pages/front-page/shared";
import OrText from "modules/selections/or-text";
import { Section, SectionTitle } from "modules/selections/common";
import { PrimaryInput } from "components/input";
import { PurpleButton } from "components/purple-button";
import { useShortId } from "hooks/use-short-id";
import { InlineSpan } from "components/as-inline-span";
import Link from "next/link";
import { serverRoutes } from "client-server-shared/config/routes";
import { Checkmark } from "./checkmark";
import { useTranslation } from "components/translate";
import useSearchParam from "external/react-use/useSearchParam";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import Image from "next/image";
import dynamic from "next/dynamic";
import PasswordIcon from "@mui/icons-material/Password";
import EmailIcon from "@mui/icons-material/Email";
import { LogoWithBlackText } from "components/logo";
import { colors } from "src/constants";
import { Paper } from "components/ui/paper";
const ShopifyModal = dynamic(
  () => import("modules/integration/shopify/shopify-connect-modal"),
  {
    ssr: false,
  }
);

type LoginFormData = z.infer<typeof userEmailLoginSchema>;

type RegisterFormData = z.infer<typeof userEmailRegisterSchema>;

export const LoginForm = ({ signup }: { signup?: boolean }) => {
  const allowPasswordLogin = useSearchParam("passwordLogin");

  type FormData = typeof signup extends boolean
    ? RegisterFormData
    : LoginFormData;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(
      allowPasswordLogin === "true"
        ? passwordLoginSchema
        : signup
        ? userEmailRegisterSchema
        : userEmailLoginSchema
    ),
  });
  const { t } = useTranslation("auth");
  const {
    onOAuthLogin,
    loginWithPassword,
    loginWithOwnApi,
    isLoading,
    oauthLoading,
  } = useLoginOrSignUpWithEmail();

  React.useEffect(() => {
    getBrowserSupabase();
  }, []);

  async function onSubmit(
    data: typeof signup extends boolean ? RegisterFormData : LoginFormData
  ) {
    if (signup) {
      const signupData = data as RegisterFormData;
      await loginWithOwnApi({
        email: signupData.email,
        fullName: signupData.fullName,
      });
    } else {
      if (allowPasswordLogin === "true") {
        await loginWithPassword({
          email: data.email,
          password: data.password,
        });
      } else {
        await loginWithOwnApi({
          email: data.email,
        });
      }
    }
    if (!allowPasswordLogin) {
      reset();
    }
  }
  const emailId = useShortId();
  const nameId = useShortId();
  const passwordId = useShortId();
  const signupError = errors as Partial<FieldErrorsImpl<RegisterFormData>>;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          maxWidth: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "440px",
          },
          margin: "auto",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LogoWithBlackText height={55} width={240} />
        </Box>
        <Description
          sx={{
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Use AI writing tools to generate one-click SEO-optimized articles,
          product descriptions, social media posts, emails, and more.
        </Description>
        <Paper
          sx={{
            border: `1px solid ${colors.gray2}`,
            padding: "36px",
            borderRadius: "10px",
            marginTop: "36px",
            boxShadow: 0,
          }}
        >
          <Section
            sx={{
              marginTop: "auto",
            }}
          >
            <Description>
              <InlineSpan inherit>By continuing , you agree to our </InlineSpan>{" "}
              <InlineSpan
                sx={{
                  "& a": {
                    color: colors.red1,
                  },
                }}
                inherit
              >
                <Link href={serverRoutes.terms}>Terms Of Service</Link>
              </InlineSpan>{" "}
              <InlineSpan inherit>and</InlineSpan>{" "}
              <InlineSpan
                sx={{
                  "& a": {
                    color: colors.red1,
                  },
                }}
                inherit
              >
                <Link href={serverRoutes.privacyPolicy}>Privacy Policy</Link>
              </InlineSpan>
              .
            </Description>
          </Section>
          <Box
            sx={{
              display: "flex",
              margin: "12px 0",
              flexDirection: "column",
              rowGap: "12px",
            }}
          >
            <OuthButtons
              isLoading={oauthLoading.isLoading}
              onOAuthLogin={onOAuthLogin}
            />
            {/*
              {!signup && !allowPasswordLogin && (
              <OutlineGreyButton
                onClick={() => {
                  window.location.href =
                    serverRoutes.login + "?passwordLogin=true";
                }}
                disabled={isLoading}
                sx={{
                  padding: "6px 8px",
                  width: "100%",
                  fontWeight: 700,
                }}
                startIcon={
                  <PasswordIcon
                    sx={{
                      fill: "rgb(155, 78, 151)",
                      height: "17px",
                      width: "17px",
                    }}
                  />
                }
              >
                {t("Continue with Password")}
              </OutlineGreyButton>
            )}
              */}
            {allowPasswordLogin && !signup ? (
              <OutlineGreyButton
                onClick={() => {
                  window.location.href = serverRoutes.login;
                }}
                disabled={isLoading}
                sx={{
                  padding: "6px 8px",
                  width: "100%",
                  fontWeight: 700,
                }}
                startIcon={
                  <EmailIcon
                    sx={{
                      fill: "rgb(155, 78, 151)",
                      height: "17px",
                      width: "17px",
                    }}
                  />
                }
              >
                {t("Continue with Email")}
              </OutlineGreyButton>
            ) : null}
          </Box>
          <OrText
            chipProps={{
              sx: {
                background: "transparent",
                fontSize: "14px",
              },
              label: "Or, continue with email",
            }}
          />
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <SectionTitle forId={emailId}>{t("Email")}</SectionTitle>
              <PrimaryInput
                id={emailId}
                fullWidth
                size="small"
                placeholder="you@example.com"
                type="email"
                error={!!errors?.email}
                helperText={errors?.email?.message}
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                disabled={isLoading || oauthLoading.isLoading}
                {...register("email")}
              />
            </Section>

            {allowPasswordLogin && (
              <Section>
                <SectionTitle
                  descriptionProps={{
                    sx: {
                      maxWidth: "600px",
                    },
                  }}
                  forId={nameId}
                  description={`To set your password, please log in or sign up with your email first. Then, you can set your password via https://www.junia.ai/dashboard/settings.`}
                >
                  {t("Password")}
                </SectionTitle>
                <PrimaryInput
                  id={passwordId}
                  fullWidth
                  size="small"
                  placeholder=""
                  type="password"
                  error={!!signupError?.password}
                  helperText={signupError?.password?.message}
                  disabled={isLoading || oauthLoading.isLoading}
                  {...register("password")}
                />
              </Section>
            )}
            <Section>
              <PurpleButton
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                sx={{
                  width: "100%",
                }}
              >
                {allowPasswordLogin
                  ? "Continue with email and password"
                  : "Continue with email"}
              </PurpleButton>
            </Section>
          </Box>
          {/*
            <Section>
            <Description>
              <InlineSpan
                sx={{
                  fontWeight: 600,
                }}
                inherit
              >
                {signup
                  ? t("Already have an account?")
                  : t("Don't have an account?")}{" "}
              </InlineSpan>{" "}
              <InlineSpan
                inherit
                sx={{
                  fontWeight: 700,
                  "& a": {
                    fontWeight: 700,
                    color: "rgba(255, 28, 70, 0.9)",
                  },
                }}
              >
                <Link
                  href={signup ? serverRoutes.login : serverRoutes.register}
                >
                  {signup ? t("Sign in") : t("Sign up for free")}
                </Link>
              </InlineSpan>
            </Description>
          </Section>
          */}
        </Paper>
      </Box>
    </>
  );
};
