import * as z from "zod";

export const userEmailRegisterSchema = z.object({
  email: z.string().email(),
  marketingAccepted: z.optional(z.boolean()),
});

export const userEmailLoginSchema = z.object({
  email: z.string().email(),
});

export const passwordLoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});
