import { Box } from "components/ui/box";

import { useRedirectInLogin } from "hooks/use-user";
import React from "react";

import { Container } from "modules/marketing-pages/front-page/shared";
import { LoginForm } from "./user-login-form";
import { fromParams, priceIdParams } from "client-server-shared/config/routes";
import useSearchParam from "external/react-use/useSearchParam";

const TooManyRequestListener = dynamic(() => import("./too-many-request"), {
  ssr: false,
});
import { Testmonial } from "./market";
import dynamic from "next/dynamic";
import { secondaryScrollbar } from "modules/themes/sytle-constants";

const LoginPage = ({ signup }: { signup?: boolean }) => {
  useRedirectInLogin();

  const priceId = useSearchParam(priceIdParams);

  const from = useSearchParam(fromParams);
  const query = {};
  if (from) {
    query[fromParams] = from;
  } else if (priceId) {
    query[priceIdParams] = priceId;
  }
  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        height: {
          xs: "initial",
          sm: "initial",
          md: `initial`,
          lg: "100%",
        },
        maxHeight: {
          xs: "initial",
          sm: "initial",
          md: `initial`,
          lg: "100vh",
        },
        overflow: {
          xs: "initial",
          sm: "initial",
          md: `initial`,
          lg: "hidden",
        },
        ...secondaryScrollbar,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          component="nav"
          sx={{
            height: {
              xs: "40px",
              sm: "40px",
              md: "40px",
              lg: "80px",
            },
            padding: "20px 20px",
            marginRight: "auto",
          }}
        ></Box>
        <Container
          maxWidth="xl"
          sx={{
            padding: "0px!important",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: {
                xs: "100%",
                sm: "100%",
                md: `calc(100vh - 30%)`,
                lg: `calc(100vh - 30%)`,
              },
              justifyContent: "center",
              padding: "24px",
              columnGap: "48px",
              rowGap: "48px",
              maxWidth: {
                xs: "600px",
                sm: "600px",
                md: "600px",
                lg: "100%",
              },
              margin: "auto",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              },
            }}
          >
            <LoginForm signup={signup} />
          </Box>
        </Container>
      </Box>
      <TooManyRequestListener />
    </Container>
  );
};

export default LoginPage;
