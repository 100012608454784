import { dashboardRouteConfig } from "client-server-shared/config/routes";
import { useLoading } from "hooks/use-loading";
import { getBrowserSupabase } from "utils/supabase-as-lazy";
import { useTranslation } from "components/translate";
import { toast } from "components/ui/toast/toast";
import useSearchParam from "external/react-use/useSearchParam";
import React from "react";
import { getURL } from "client-server-shared/utils/get-url";
import protectedClientApi from "utils/protected-client-api";
import { isCn } from "client-server-shared/constants";

export const useLoginOrSignUpWithEmail = () => {
  const { t } = useTranslation("auth");

  const { isLoading, onIdle, onLoading } = useLoading();
  const oauthLoading = useLoading();
  const from = useSearchParam("from");
  const redirectTo = `${getURL(from || dashboardRouteConfig.dashboard)}`;
  const loginWithOwnApi = React.useCallback(
    async ({ email, fullName }: { email: string; fullName?: string }) => {
      if (isLoading || oauthLoading.isLoading) {
        return;
      }
      onLoading();

      try {
        await protectedClientApi.login({
          email,
          fullName,
          redirectUrl: redirectTo,
          locale: isCn() ? "cn" : "en",
        });
        toast({
          title: "Check your email",
          message: "We sent you a login link. Be sure to check your spam too.",
          type: "success",
          position: "bottom-center",
        });
        onIdle();
      } catch (e) {
        toast({
          title: "Sign in failed",
          message: "Sign in failed. Please try again later.",
          type: "error",
          position: "bottom-center",
        });
        onIdle();
      }
    },
    [redirectTo, isLoading, oauthLoading.isLoading]
  );

  const onOAuthLogin = React.useCallback(
    async (provider: string) => {
      if (isLoading || oauthLoading.isLoading) {
        return;
      }
      oauthLoading.onLoading();
      const supabase = await getBrowserSupabase();
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: { redirectTo },
      });
      if (error) {
        toast({
          title: t("Sign in request failed"),
          message: error.message,
          type: "error",
          position: "bottom-center",
        });
        oauthLoading.onIdle();
      }
    },
    [redirectTo, oauthLoading.isLoading, isLoading]
  );

  const loginWithPassword = React.useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      if (isLoading || oauthLoading.isLoading) {
        return;
      }
      onLoading();

      const supabase = await getBrowserSupabase();

      const { data, error } = await supabase.auth.signInWithPassword({
        email: email.toLowerCase(),
        password: password,
      });
      if (error) {
        toast({
          title: "Sign in request failed",
          message:
            error?.message || "Sign in request failed, please try again later.",
          type: "error",
          position: "bottom-center",
        });
      }
      onIdle();
    },
    [isLoading, redirectTo, oauthLoading.isLoading]
  );

  const loginWithSupabase = React.useCallback(
    async ({ email }: { email: string }) => {
      if (isLoading || oauthLoading.isLoading) {
        return;
      }
      onLoading();

      const supabase = await getBrowserSupabase();

      const { error } = await supabase.auth.signInWithOtp({
        email: email.toLowerCase(),
        options: {
          emailRedirectTo: redirectTo,
        },
      });
      if (error) {
        toast({
          title: t("Sign in request failed"),
          message: error.message,
          type: "error",
          position: "bottom-center",
        });
      } else {
        toast({
          title: t("Check your email"),
          message: t(
            "We sent you a login link. Be sure to check your spam too."
          ),
          type: "success",
          position: "bottom-center",
        });
      }
      onIdle();
    },
    [isLoading, redirectTo, oauthLoading.isLoading]
  );

  const signUpWithSupabase = React.useCallback(
    async ({ email, fullName }: { email: string; fullName: string }) => {
      if (isLoading || oauthLoading.isLoading) {
        return;
      }
      onLoading();

      const supabase = await getBrowserSupabase();

      const { error } = await supabase.auth.signInWithOtp({
        email: email.toLowerCase(),
        options: {
          data: {
            name: fullName,
          },
          emailRedirectTo: redirectTo,
        },
      });
      if (error) {
        toast({
          title: "Sign up failed",
          message: error.message,
          type: "error",
          position: "bottom-center",
        });
      } else {
        toast({
          title: "Check your email",
          message: t(
            "We sent you a login link. Be sure to check your spam too."
          ),
          type: "success",
          position: "bottom-center",
        });
      }
      onIdle();
    },
    [isLoading, redirectTo, oauthLoading.isLoading]
  );

  return {
    loginWithPassword,
    redirectTo,
    loginWithSupabase,
    signUpWithSupabase,
    isLoading,
    onOAuthLogin,
    loginWithOwnApi,
    oauthLoading,
  };
};
