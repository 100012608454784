import { useDashboardTranslation } from "components/translate";
import { BoxProps } from "components/ui/box";
import { Chip, ChipProps } from "components/ui/chip";
import { Divider } from "components/ui/divider";

const OrText = ({
  sx = {},
  chipProps = {},
}: {
  sx?: BoxProps["sx"];
  chipProps?: ChipProps;
}) => {
  const { t } = useDashboardTranslation();

  return (
    <Divider sx={sx}>
      <Chip label={t("Or")} {...chipProps} />
    </Divider>
  );
};

export default OrText;
