import * as React from "react";

import { Button } from "components/ui/button";
import { CircularProgress } from "components/ui/loading";
import { Divider } from "components/ui/divider";
import { GoogleColorIcon } from "client-server-shared/icons/google-color-icon";
import { useTranslation } from "components/translate";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";

export const OuthButtons = ({
  isLoading,
  onOAuthLogin,
}: {
  isLoading?: boolean;
  onOAuthLogin: (provider: string) => void;
}) => {
  const { t } = useTranslation("auth");

  return (
    <OutlineGreyButton
      onClick={() => onOAuthLogin("google")}
      disabled={isLoading}
      sx={{
        padding: "6px 8px",
        width: "100%",
        fontWeight: 700,
      }}
      startIcon={
        isLoading ? (
          <CircularProgress size={17} />
        ) : (
          <GoogleColorIcon
            sx={{
              height: "17px",
              width: "17px",
            }}
          />
        )
      }
    >
      {"Continue with Google"}
    </OutlineGreyButton>
  );
};

export const EmailSignInButton = ({ isLoading }: { isLoading?: boolean }) => {
  const { t } = useTranslation("common");

  return (
    <Button
      type="submit"
      marginTop={10}
      variant="contained"
      disabled={isLoading}
    >
      {isLoading && (
        <CircularProgress textColor="main" size={20} marginRight={10} />
      )}
      {t("Sign In with Email")}
    </Button>
  );
};

export const OrContinueWith = () => {
  const { t } = useTranslation("common");

  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t "></div>
      </div>
      <Divider>{t("Or continue with")}</Divider>
    </div>
  );
};
