import { useRedirectInLogin } from "hooks/use-user";
import {
  fromParams,
  priceIdParams,
  serverRoutes,
  serverRoutesConfig,
} from "client-server-shared/config/routes";
import useSearchParam from "external/react-use/useSearchParam";
import { PageHeadTags } from "common/head-tags";
import { useTranslation } from "components/translate";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { locale } from "client-server-shared/constants";
import LoginPage from "modules/auth/login-page";

export default function Login() {
  useRedirectInLogin();

  const priceId = useSearchParam(priceIdParams);

  const from = useSearchParam(fromParams);
  const query = {};
  if (from) {
    query[fromParams] = from;
  } else if (priceId) {
    query[priceIdParams] = priceId;
  }
  const { t } = useTranslation("login");
  return (
    <>
      <PageHeadTags
        pageLink={serverRoutesConfig.login}
        title="AI writing tools - Login"
        description="Login to your writingtools.ai account"
      />
      <LoginPage />
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["auth", "login", "common"])),
      // Will be passed to the page component as props
    },
  };
}
